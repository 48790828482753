<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'Reminders', query: this.createSearchQuery()}" class="ashen-link">リマインダー管理
          </router-link>
          <i class="fas fa-angle-right form-control-color"></i> リマインダー新規登録
        </p>
      </div>
    </div>
    <div class="card text-first">
      <div class="card-header bg-transparent">
        <img src="../assets/images/racket-gray.png"/>
        <span class="mx-3">{{ tournamentName }}</span>
      </div>
      <div class="card-body mt-4 mb-4 px-5">
        <div class="row">
          <div class="col">
            <label for="exampleInputPassword1">タイトル</label>
            <input v-model="reminder.reminderTitle" class="form-control half-width-input mt-1" type="text">
            <div v-show="errors['reminderTitle']" class="form-error">{{ errors['reminderTitle'] }}</div>
          </div>
        </div>
        <div class="row mt-4 dob-section p-l-0">
          <label>時刻</label>
          <div class="d-inline-flex mt-1">
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">時</div>
              <select id="inputGroupSelect02" v-model="reminder.hour" class="form-control">
                <option v-for="hour in hours" :value="hour">
                  {{ hour < 10 ? '0' + hour : hour }}
                </option>
              </select>
            </div>
            <div v-show="errors['hour']" class="form-error">{{ errors['hour'] }}</div>
            <div class="input-group custom-select-year parent-block">
              <div class="child-block">分</div>
              <select id="inputGroupSelect02" v-model="reminder.minute" class="form-control">
                <option v-for="minute in minutes" :value="minute">
                  {{ minute < 10 ? '0' + minute : minute }}
                </option>
              </select>
            </div>
            <div v-show="errors['minute']" class="form-error">{{ errors['minute'] }}</div>
            <!--                        <div class="input-group custom-select-year parent-block m-r-0">-->
            <!--                            <div class="child-block">日</div>-->
            <!--                            <select class="form-control" id="inputGroupSelect02">-->
            <!--                                <option selected>月</option>-->
            <!--                                <option value="1">01</option>-->
            <!--                                <option value="2">02</option>-->
            <!--                                <option value="3">03</option>-->
            <!--                            </select>-->
            <!--                        </div>-->
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <label>送信先</label>
            <select v-model="reminder.sendTargetId" class="form-control destination form-select mt-1" disabled>
              <option :value="Number(1)">関係者全員</option>
            </select>
            <div v-show="errors['sendTargetId']" class="form-error">{{ errors['sendTargetId'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">件名</label>
            <input v-model="reminder.subject" class="form-control half-width-input mt-1" type="text">
            <div v-show="errors['subject']" class="form-error">{{ errors['subject'] }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label>本文</label>
            <textarea v-model="reminder.body" class="form-control" cols="50" rows="10"></textarea>
            <div v-show="errors['body']" class="form-error">{{ errors['body'] }}</div>
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-between">
          <div class="col">
            <button
                class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                @click="backToReminder"
            >
              キャンセル
            </button>
          </div>
          <div class="col">
            <button
                class="btn col btn-primary btn-outline-light font-weight-bold w-100"
                @click="createReminder"
            >登録
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";
import {apiConfig} from "../../../ip/src/constants";

export default {
  name: "ReminderRegistration",
  props: {
    tournamentId: {type: Number, default: null}
  },
  data() {
    return {
      apiConfig: apiConfig,
      loading: true,
      tournamentList: {},
      tournamentName: '',
      hours: [...Array(24).keys()],
      minutes: [...Array(60).keys()].filter(item => item % 5 === 0),
      reminder: {
        reminderTitle: 'リマインダー ',
        hour: 8,
        minute: 0,
        sendTargetId: 1,
        subject: '',
        body: ''
      },
      reloadKey: 1,
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
  },
  mounted() {
    this.apiConfig.headers["X-API-Token"] = this.config.token;
    this.getTournament()
  },
  methods: {
    backToReminder() {
      this.$router.push({name: "Reminders", query: this.createSearchQuery()});
    },
    async createReminder() {
      try {
        this.errors = [];
        const apiConfig = new AdminApi.ApiConfig(
            this.apiConfig.basePath,
            this.apiConfig.headers
        );
        var api = new AdminApi.TournamentApi(apiConfig);
        var result = await api.tournamentsTournamentIdRemindersPostAsync(
            this.tournamentId,
            this.reminder
        );
        Common.showToast("リマインダーが登録されました。")
        this.backToReminder();
      } catch (e) {
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(this.getApiConfig());
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      );
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

textarea.form-control {
  min-height: calc(1.6em + 1.75rem + 160px) !important;
}

.delete {
  width: 160px;
  height: 44px;
}

.destination.form-control {
  background-color: #F8F8F8 !important;
  color: #888888;
  background-image: url("../assets/images/down.svg");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0px 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

::placeholder {
  color: #888888;
}

.custom-button {
  background: #c95d6c;
  color: #ffffff;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 24%;
  margin-right: 16px;

  &:first-child {
    width: 24%;
  }

  &:last-child {
    width: 24%;
    margin-right: 0px !important;
  }
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}
</style>